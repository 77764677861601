import React from 'react'
import PropTypes from 'prop-types';


const Article = (props) => {
  const { source, title, url, urlToImage, description} = props.article;

    const image = (urlToImage) ?
    <div className="card-image">
      <img src={urlToImage} alt={title}/>
      <span className="card-title">
        {source.name}
      </span>
    </div>
    : '';

  return(
        <div className="col s12 m6 l4">
          <div className="card">
            {image}
            <div className="card-content">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
            <div className="card-action">
              <a href={url} target="_blank" className="waves-effects waves-light btn" rel="noopener noreferrer">
                Keep reading...
              </a>
            </div>
          </div>
        </div>
  )
}

export default Article

Article.propTypes = {
  article: PropTypes.shape({
    urlToImage: PropTypes.string,
    url : PropTypes.string,
    title : PropTypes.string,
    source : PropTypes.object,
    description : PropTypes.string
  })
}
