import React,{Component} from 'react';
import PropTypes from 'prop-types';

export default class Form extends Component {

  categoryRef = React.createRef();

    submitCategory = e => {
      e.preventDefault();

      this.props.newsConsult(this.categoryRef.current.value);
    }

    render() {
        return (
            <div className="buscador row">
                <div className="col s12 m8 offset-m2">
                <form onSubmit={this.submitCategory}>
                  <h2>News by Category</h2>
                  <div className="input-field col s12 m7">
                    <select name="" id="" ref={this.categoryRef}>
                      <option value="general" defaultValue>General</option>
                      <option value="business">Business</option>
                      <option value="entertainment">Entertainment</option>
                      <option value="health">Health</option>
                      <option value="science">Science</option>
                      <option value="sports">Sports</option>
                      <option value="technology">Technology</option>
                    </select>
                  </div>
                  <div className="input-field col s12 m5 enviar">
                    <input type="submit" className="btn amber darken-2" value="Search"/>
                  </div>
                </form>
                </div>
            </div>
        );
    }
}

Form.propTypes = {
  newsConsult : PropTypes.func.isRequired
}
