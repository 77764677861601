import React,{Component} from 'react';
import Article from './Article'
import PropTypes from 'prop-types';
import {TransitionGroup, CSSTransition} from 'react-transition-group'

export default class News extends Component {

    render() {
        return (
            <div className="row">
              <TransitionGroup>
                {this.props.news.map( article => (
                <CSSTransition
                  key={article.url}
                  classNames="fade"
                  timeout={500}>
                    <Article
                      article={article}/>
                </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
        );
    }
}

News.propTypes = {
  news : PropTypes.array.isRequired
}
