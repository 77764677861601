import React,{Component} from 'react';
import Header from './components/Header'
import News from './components/News'
import Form from './components/Form'
export default class App extends Component {

    state = {
      news : [],

    }
    componentDidMount() {
      this.newsConsult();
    }

    newsConsult = (category = 'general') => {



      let url = `https://newsapi.org/v2/top-headlines?country=us&category=${category}&apiKey=b17d28e75a034263b3c0d5e3f0027eb6`;

      fetch(url).then(
        rta => {
          return rta.json();
        }).then(news => {
          this.setState({
          news : news.articles
        })
        })
    }

    render() {
        return (
            <div className="contenedor-app">
                <Header title="News"/>
                <div className="container white contenedor-noticias">
                  <Form
                  newsConsult={this.newsConsult}/>
                  <News news={this.state.news} />
                </div>
            </div>
        );
    }
}
